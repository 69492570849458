<template>
    <div class="page">
        <div class="html-container" id="htmlContainer" v-html="info.exceptionHandle" />
    </div>
</template>
<script>
import { APPJS } from '@/utils/native';
import http from '@/http';

export default {
    data(){
        return {
            info: null
        }
    },
    created() {
        http.post('/?cmd=com.xqxc.api.pigear.biz.platform.TransportCompanyService.getExceptionHandle', {
            code: this.$route.query.code
        }).then(res => {
            this.info = res.result;
            document.title = res.result.title;
        })
    },
    mounted() {
    },
    methods: {
        closePage(){
            APPJS('closeweb');
        },

    },
}
</script>
<style lang="less" scoped>
#htmlContainer{
    /deep/ video{width: 7.5rem}
}
.page { 
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .html-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 1rem;
        left: 0;
        overflow: auto;
        font-size: .28rem;
        padding: .3rem;
        word-break: break-all;
        word-wrap:break-word;
        table{
            max-width: 100%;
            margin: .1rem auto;
        }
        /deep/ p { line-height:1.2; margin-top:.25rem; text-indent: .56rem}
        /deep/ p strong{ font-weight: bold;}
        /deep/ p b{ font-weight: bold;}
        /deep/ img{ max-width: 100%;}
        /deep/ video{ max-width: 100%;}
    }
    .footer{
        position: absolute;
        width: 100%;
        height: 1rem;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .closeBtn{
        width: 6.9rem;
        height: .88rem;
        border-radius: .1rem;
        background: #00ABEB;
        color: #FFF;
        font-size: .32rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
    
</style>
    